import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { deleteScenario } from "../../../../api/scenario";
import { FinanceContext } from "../../../../context/finance-context";
import { useQueryInvalidationWithNotification } from "../../../../hooks";
import { TrashBin } from "../../../../images";

const RemoveScenarioComponent = ({ open, handleDialogClose = null }) => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  const { selectedScenarioId } = useContext(FinanceContext);
  const [deleteContent, setDeleteContent] = useState({
    header: "Delete Scenario?",
    body: "You'll permanently lose your scenario Data",
  });

  const {
    mutate: doDeleteScenario,
    isSuccess,
    isLoading,
    reset: resetDeleteMutation,
  } = useMutation((id) => deleteScenario(id), {
    onSuccess: () => {
      invalidateQueries("scenarioList");
      setDeleteContent({
        header: "Success!",
        body: "Your scenario was successfully deleted.",
      });
    },
    onError: (err) => {
      setDeleteContent({
        header: "Error!",
        body: err?.data?.message,
      });
    },
  });

  const handleCloseModal = (e) => {
    e.stopPropagation();
    handleDialogClose(false);
    resetDeleteMutation();
    setTimeout(() => {
      setDeleteContent({
        header: "Delete Scenario?",
        body: "You'll permanently lose your scenario Data",
      });
    }, 500);
  };

  const handleDelete = () => {
    doDeleteScenario(selectedScenarioId);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="form-dialog-title"
      className="custom-modal-container"
    >
      <div
        role="presentation"
        className="custom-modal-body"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="delete-property-context">
          <div className="delete-property-header">{deleteContent.header}</div>
          <div className="delete-property-body">{deleteContent.body}</div>
        </div>
        <div className="delete-property-trashbin">
          <img src={TrashBin} alt="" />
        </div>
      </div>

      <div className="custom-modal-button-group">
        {isSuccess ? (
          <>
            <button
              type="button"
              className="btn delete-property"
              onClick={handleCloseModal}
            >
              Ok
            </button>
          </>
        ) : (
          <>
            <button type="button" className="btn " onClick={handleCloseModal}>
              Cancel
            </button>
            <button
              type="button"
              className="btn delete-property"
              onClick={handleDelete}
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Delete Scenario"
              )}
            </button>
          </>
        )}
      </div>
    </Dialog>
  );
};

RemoveScenarioComponent.propTypes = {
  open: PropTypes.bool,
  handleDialogClose: PropTypes.func,
};

export default RemoveScenarioComponent;
